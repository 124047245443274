export const testAttr = {
  ADS_CONTAINER: "ads-container",
};

export const adsData = {
  HOME: [
    {
      imageSrc: `/assets/images/ads/ecard.jpg`, 
      title: 'Amazon.com eGift Card (Instant Email or Text Delivery)',
      altText: 'eGift Card',
      addLink: 'https://amzn.to/3Dq32tS'
    }
  ],
  OTHERPAGES: [
    {
      imageSrc: `/assets/images/ads/ecard.jpg`, 
      title: 'Amazon.com eGift Card (Instant Email or Text Delivery)',
      altText: 'eGift Card',
      addLink: 'https://amzn.to/3Dq32tS'
    },
    {
      imageSrc: '/assets/images/ads/suitacases.jpg', 
      title: 'Amazon Basics Suitcases with Wheels, 3-Piece Hardside Luggage Set with Spinner Wheels',
      altText: 'Amazon Basics Suitcases with Wheels',
      addLink: 'https://amzn.to/3VPoRcs'
    }
  ]
}