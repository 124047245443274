import React, { useState } from "react";
import PropTypes from "prop-types";
import { testAttr } from "./Constants";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import NewsList from "../../Atoms/NewsList/NewsList";
import Ads from "../../Atoms/Ads/Ads";
import { adsData } from "../../Atoms/Ads/Constants"
import i18n from "../../../i18n";
import "./News.scss";
import parse from 'html-react-parser';
import { useLocation } from "react-router-dom";

const News = ({ newsList }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const newsSelected = location.state?.dataSelected;

  const [mainNews, setMainNews] = useState(newsSelected ?? newsList[0]);

  const filteredNewsList = newsList.filter(news => news.id !== mainNews.id);

  const handleItemClick = (clickedNews) => {
    setMainNews(clickedNews);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <div data-testid={testAttr.NEWS_CONTAINER} className="newsContainer">
        <h2>{t("newsTitle")}</h2>

        <div key={mainNews.id} className="article">
          <div className="leftPanel">
            <img src={mainNews.image} alt={mainNews.title} className="newsImage" />
            <div className="newsListContainerWrapper">
              <NewsList 
                list={filteredNewsList} 
                onItemClick={handleItemClick}
              />
            </div>
          </div>

          <div>
            <h2 className="newsTitle">{mainNews.title}</h2>
            <p className="newsDate">{mainNews.date}</p>
            <p className="newsDescriptionPage">{parse(mainNews.description)}</p>

            <div data-testid={testAttr.NEWS_ADS}>
              <hr></hr>
            <Ads data={adsData.OTHERPAGES} />
        </div>
          </div>
        </div>

      </div>
    </I18nextProvider>
  );
};

News.propTypes = {
  newsList: PropTypes.array.isRequired,
};

export default News;
