import React from "react";
import { Link } from "react-router-dom"; // Import Link
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../i18n";
import PropTypes from "prop-types";
import { testAttributes } from "./Constants";
import "./Header.scss";

const Header = ({ navItems }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const LogoComp = () => <div className="headerIcon" aria-label="Go to homepage" />;

  const { t } = useTranslation();

  return (
    <I18nextProvider i18n={i18n}>
      <div
        data-testid={testAttributes.HEADER_COMPONENT}
        className="headerWrapper"
      >
        <AppBar>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "flex" },
                }}
              >
                <LogoComp />
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  data-testid={testAttributes.HEADER_OPEN_MOBILE_MENU}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {navItems.map((item) => (
                    <MenuItem key={item} onClick={handleCloseNavMenu}>
                      <Link to={item === t("festivals") ? "/fests" : "/"}>
                        <Typography
                          textAlign="center"
                          data-testid={testAttributes.HEADER_CLOSE_MOBILE_MENU}
                        >
                          {t(item)}
                        </Typography>
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {navItems.map((item) => (
                  <Link
                    key={item}
                    to={item === t("festivals") ? "/fests" : item === t("news") ? "/news" : "/"}
                    style={{ textDecoration: "none" }}
                  >
                    <Button onClick={handleCloseNavMenu} sx={{ my: 2 }} className="navItems">
                      {t(item)}
                    </Button>
                  </Link>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </I18nextProvider>
  );
};

Header.propTypes = {
  navItems: PropTypes.array.isRequired,
};

export default Header;
