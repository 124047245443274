import React from "react";
import PropTypes from "prop-types";
import "./Banner.scss";
import { testAttr } from "./Constants";

const Banner = ({ title, path }) => (
  <div data-testid={testAttr.BANNER_CONTAINER} className="bannerContainer">
    <figure>
      <img alt={title} src={path} />
      {/* <figcaption>{title && <h3>{title}</h3>}</figcaption> */}
    </figure>
  </div>
);

Banner.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string.isRequired,
};

export default Banner;
