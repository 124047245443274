/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import Home from "../Home/Home";
import FestsList from "../FestsList/FestsList";
import FestInfo from "../FestInfo/FestInfo";
import Banner from "../../Atoms/Banner/Banner";
import News from "../News/News";
import { BrowserRouter as Router, Routes, Route, useLocation, Link, Navigate } from "react-router-dom";
import "./AppWrapper.scss";
import dataInSpanish from "../../../state/dataES";
import dataInEnglish from "../../../state/dataEN";
import newsInSpanish from "../../../state/newsES";
import newsInEnglish from "../../../state/newsEN";
import { useSetLanguage } from "../../../utils/useSetLanguage/useSetLanguage";
import getRandomElement from "../../../utils/getRandomElement/getRandomElement";
import sortFestList from "../../../utils/sortFestList/sortFestList";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "../../../i18n";
import { bannerPath } from "./Constants";
import ScrollToTop from "../../../utils/scrollTo/scrollTo"

const AppContent = ({ randomFestToShow, initialState, setFestSelected, festData, navItems }) => {
  const location = useLocation();
  
  return (
    <div className="pageWrapper">
      <Header navItems={navItems} />
      <div className="content">
        {location.pathname === "/" && randomFestToShow && (
          <Link to={`/fest/${randomFestToShow.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-")}`} onClick={() => setFestSelected(randomFestToShow.id)}>
            <Banner
              title={randomFestToShow.title}
              path={`${bannerPath}${randomFestToShow.bannerPath}`}
            />
          </Link>
        )}
        <Routes>
          <Route path="/" element={<Home homeFestList={initialState?.data} onClickFest={setFestSelected} />} />
          <Route path="/fests" element={<FestsList festList={initialState?.data} onClickFest={setFestSelected} />} />
          <Route path="/fest/:id" element={<FestInfo content={festData} newsList={initialState?.newsData} />} />
          <Route path="/news" element={<News newsList={initialState.newsData} />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const AppWrapper = () => {
  const [festSelected, setFestSelected] = useState();
  const initialState = {
    userLang: "",
    festIdSelected: "",
    data: useSetLanguage() === "en-US" ? dataInEnglish : dataInSpanish,
    newsData: useSetLanguage() === "en-US" ? newsInEnglish : newsInSpanish,
  };

  const randomFestToShow = getRandomElement(sortFestList(initialState?.data));
  let festData = initialState?.data.find(fest => fest.id === festSelected);

  const { t } = useTranslation();
  const navItems = [t("home"), t("festivals"), t("news")];

  const currentPath = window.location.pathname;
  const urlPattern = /^\/(fest)(\/.*)?$/;
  const match = currentPath.match(urlPattern);
  if (match && match[1] === 'fest') {
    const convertedUrl = match[2].replace(/^\//, '').replace(/-/g, ' ');
    const festDataPreSelected = initialState?.data.find(fest => fest.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === convertedUrl);
    festDataPreSelected ? festData = festDataPreSelected : window.location.href = '/';
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <ScrollToTop />
        <AppContent 
          randomFestToShow={randomFestToShow} 
          initialState={initialState} 
          setFestSelected={setFestSelected} 
          festData={festData} 
          navItems={navItems} 
        />
      </Router>
    </I18nextProvider>
  );
};

export default AppWrapper;
