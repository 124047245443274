import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import ModalDialog from "../ModalDialog/ModalDialog";
import i18n from "../../../i18n";
import { testAttributes } from "./Constants";
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <I18nextProvider i18n={i18n}>
      <div
        data-testid={testAttributes.FOOTER_COMPONENT}
        className="footerWrapper"
      >
        <span role="button" onClick={handleOpen}>{t("legalWarningTitle")}</span>
        <span>{t("emailUs")} festcolombia.co@outlook.com</span>
        <span>
          &copy; {currentYear.getFullYear()} {t("copyRight")}
        </span>
      </div>
      <ModalDialog isOpen={open} setIsOpen={setOpen} />
    </I18nextProvider>
  );
};

export default Footer;
